import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import dynamic from 'next/dynamic';
import React from 'react';
import type { SubscriptionActionVariant } from '../../../types/enums';
import type { ContextMessageFragment } from '../../../types/graphql-types';

interface Props {
  /**
   * The message for which follow action needed.
   */
  message: ContextMessageFragment;
  /**
   * View variant
   */
  variant?: SubscriptionActionVariant;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Whether to use a success feedback or not.
   */
  useSuccessFeedback?: boolean;

  /**
   * Whether to use an effect when hovering over the button or not.
   */
  useHoverEffect?: boolean;
  /**
   * Whether to use divider before when using Dropdown item variant.
   */
  useDivider?: boolean;
  /**
   * Function executed after a subscription delete is completed.
   */
  onSubscriptionDeleteCompleted?: () => void;
}

const MessageSubscriptionActionMain = dynamic(() => import('./MessageSubscriptionActionMain'), {
  ssr: false
});
/**
 * A message subscription component with variants to subscribe/unsubscribe to messages.
 *
 * @author Martin Sandoval
 */
const MessageSubscriptionAction: React.FC<React.PropsWithChildren<Props>> = props => {
  const { isAnonymous } = useRegistrationStatus();
  if (isAnonymous) {
    return null;
  } else {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MessageSubscriptionActionMain {...props} />;
  }
};

export default MessageSubscriptionAction;
