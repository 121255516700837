import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ActionButtonVariant,
  ButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { canManageAbuseContent } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import { ModerationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ContextMessageFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import type {
  MessageActionMenuFragment,
  ModerationDataQuery,
  ModerationDataQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import moderationDataQuery from '../MessageActionMarkAsSpam/MessageModerationData.query.graphql';

const RejectMessageModal = dynamic(() => import('./RejectMessageModal/RejectMessageModal'));

interface Props {
  /**
   * Whether the component is rendered from the Manage Content Dashboard.
   */
  isDashboardAction?: boolean;
  /**
   * Variant of the action button.
   */
  actionButtonVariant?: ActionButtonVariant;
  /**
   * Message object to be rejected.
   */
  message: MessageActionMenuFragment | ContextMessageFragment;
  /**
   * Classname(s) to apply to the component.
   */
  className?: string;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
}

/**
 * The action to reject a message as abuse/spam/other
 * @constructor
 * @author Deepak Yadav
 */
const MessageActionReject: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  actionButtonVariant = ActionButtonVariant.DROP_DOWN_BUTTON,
  isDashboardAction = false,
  onCloseSubjectModal,
  onHideSubjectModal
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_REJECT
  );
  const [showRejectMessageModal, setShowRejectMessageModal] = useState<boolean>(false);
  const { isAnonymous } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const cx = useClassNameMapper();
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanModerateMessage: true,
      useCanModerateEntity: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  const { data: nodePoliciesData, loading: nodePoliciesLoading } = useNodePolicies(
    module,
    {
      useCanManageAbuseContent: true,
      id: message.board.id
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  const { data: queryData, loading: queryLoading } = useQueryWithTracing<
    ModerationDataQuery,
    ModerationDataQueryVariables
  >(module, moderationDataQuery, {
    variables: {
      id: message.id
    },
    skip: IdConverter.isOptimistic(tenant, message?.id)
  });

  const canModerateSpamMessage = checkPolicy(
    policiesData?.message?.messagePolicies?.canModerateSpamMessage
  );

  const canModerateEntity = checkPolicy(policiesData?.message?.messagePolicies?.canModerateEntity);

  if (isAnonymous || textLoading || policiesLoading || queryLoading || nodePoliciesLoading) {
    return null;
  }

  const canModerateAbuseMessage = canManageAbuseContent(nodePoliciesData?.coreNode);

  const { status } = queryData?.message?.moderationData || {};

  const isRejected = status === ModerationStatus.Rejected;

  /**
   * Handles hide or cancel dialog action
   */
  function onHideOrCancelDialog() {
    if (actionButtonVariant === ActionButtonVariant.SUBJECT_MODAL_BUTTON) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    setShowRejectMessageModal(false);
  }

  /**
   * Renders reject type modal
   */
  const renderRejectMessageModal = (): React.ReactElement => {
    return (
      <RejectMessageModal
        show={showRejectMessageModal}
        onHide={() => onHideOrCancelDialog()}
        message={message}
        isDashboardAction={isDashboardAction}
        messageModerationPermissions={{
          canModerateAbuseMessage,
          canModerateSpamMessage,
          canModerateEntity
        }}
      />
    );
  };

  /**
   * Renders the button as a drop-down item.
   */
  function renderAsDropDownItemButton(): React.ReactElement {
    return (
      <Dropdown.Item
        onClick={(): void => setShowRejectMessageModal(true)}
        data-testid="MessageActionReject"
      >
        {formatMessage('title')}
      </Dropdown.Item>
    );
  }

  /**
   * Renders the button as a toolbar button.
   */
  function renderAsToolbarButton(): React.ReactElement {
    return (
      <Button
        variant={ButtonVariant.SECONDARY}
        onClick={(): void => setShowRejectMessageModal(true)}
        className={cx(className)}
        data-testid="MessageActionReject"
      >
        {formatMessage('title')}
      </Button>
    );
  }

  /**
   * Renders the button as a dashboard subject modal button.
   */
  function renderAsSubjectModalButton(): React.ReactElement {
    return (
      <Button
        data-testid="MessageActionReject"
        variant={ButtonVariant.LIGHT}
        size="lg"
        onClick={() => {
          onHideSubjectModal(true);
          setShowRejectMessageModal(true);
        }}
      >
        {formatMessage('title')}
      </Button>
    );
  }

  /**
   * Renders the Action button based on the ActionButtonVariant.
   */
  function renderActionButton(): React.ReactElement {
    switch (actionButtonVariant) {
      case ActionButtonVariant.TOOLBAR_BUTTON: {
        return renderAsToolbarButton();
      }
      case ActionButtonVariant.SUBJECT_MODAL_BUTTON: {
        return renderAsSubjectModalButton();
      }
      default: {
        return renderAsDropDownItemButton();
      }
    }
  }

  return (
    <>
      {(canModerateSpamMessage || canModerateAbuseMessage || canModerateEntity) &&
        !isRejected &&
        renderActionButton()}
      {showRejectMessageModal && renderRejectMessageModal()}
    </>
  );
};

export default MessageActionReject;
