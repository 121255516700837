import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import type { MessageReplyPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import { getLog } from '@aurora/shared-utils/log';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';

const log = getLog(module);

type Props = MessageActionType & { componentId: EndUserComponent };

/**
 * Action to copy a message's link.
 *
 * @author Jitiendran KS
 */
const CopyMessageLink: React.FC<React.PropsWithChildren<Props>> = ({ message, componentId }) => {
  const { formatMessage, loading: textLoading } = useTranslation(componentId);
  const { addToast } = useToasts();
  const { router } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();

  if (textLoading) {
    return null;
  }

  const {
    uid,
    conversation: { topic }
  } = message;

  const { messageReplyPage } = ConversationStyleBehaviorHelper.getInstance(message.board);
  const relativeUrlForRoute =
    componentId === EndUserComponent.MESSAGE_ACTION_COPY_PUBLISHED_URL
      ? router.getPathWithoutQueryStrings()
      : router.getRelativeUrlForRoute<MessageReplyPagesAndParams>(messageReplyPage, {
          boardId: getCaseSensitivePath(message.board.displayId),
          messageSubject: getCaseSensitivePath(UrlHelper.determineSlugForMessagePath(topic)),
          messageId: topic.uid.toString(),
          replyId: uid.toString()
        });

  const safeOriginUrl = UrlHelper.sanitize(window.location.origin);
  const safeRelativeUrlForRoute = UrlHelper.sanitize(relativeUrlForRoute);
  const url = UrlBuilder.fromUrl(safeOriginUrl).addPath(safeRelativeUrlForRoute).build();

  function copyMessageURL(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          log.info('Copied text to the clipboard: %s', url);
          const toastProps: ToastProps = {
            toastVariant: ToastVariant.FLYOUT,
            alertVariant: ToastAlertVariant.SUCCESS,
            autohide: true,
            title: formatMessage('linkCopied.title'),
            message: formatMessage('linkCopied.description'),
            id: `message-${url}`
          };
          addToast(toastProps);
          return null;
        })
        .catch(error => {
          log.error(error, 'Unable to copy link');
        });
    } else {
      log.warn('navigator.clipboard is not defined. This feature requires https.');
    }
  }

  return (
    <Dropdown.Item
      href={url}
      onClick={event => copyMessageURL(event as React.MouseEvent<HTMLButtonElement>)}
    >
      {formatMessage('copyLink')}
    </Dropdown.Item>
  );
};

export default CopyMessageLink;
