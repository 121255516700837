import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { Forum, SalesforceMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import type {
  EscalationPropertiesQuery,
  EscalationPropertiesQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';
import {
  canEscalateForumMessage,
  canEscalateOwnForumMessage
} from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import escalationPropertiesQuery from './EscalationProperties.query.graphql';

const EscalateMessageModal = dynamic(() => import('./EscalateMessageModal/EscalateMessageModal'));

/**
 * The message action to escalate a forum message
 *
 * @author Manasvini Arul
 */
const MessageActionEscalateToSalesforce: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const { isAnonymous } = useRegistrationStatus();
  const [showEscalateCaseModal, setShowEscalateCaseModal] = useState<boolean>(false);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_ESCALATE_TO_SALESFORCE
  );
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { salesforceIntegrationEnabled }
  } = tenant;
  const { authUser } = useContext(AppContext);
  const isForumMessage = message.board.conversationStyle === ConversationStyle.Forum;
  const isEscalatedMessage = (message as SalesforceMessage).isEscalated;

  const { data: propertiesData, loading: propertiesLoading } = useQueryWithTracing<
    EscalationPropertiesQuery,
    EscalationPropertiesQueryVariables
  >(module, escalationPropertiesQuery, {
    variables: {
      id: message.board.id
    },
    skip: isAnonymous || !(salesforceIntegrationEnabled && isForumMessage && !isEscalatedMessage)
  });

  const { data: nodePoliciesData, loading: nodePoliciesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanEscalateMessage: isForumMessage,
      useCanEscalateOwnMessage: isForumMessage
    },
    isAnonymous || !(salesforceIntegrationEnabled && isForumMessage && !isEscalatedMessage)
  );
  if (
    textLoading ||
    propertiesLoading ||
    !propertiesData?.coreNode ||
    nodePoliciesLoading ||
    !nodePoliciesData?.coreNode ||
    isEscalatedMessage
  ) {
    return null;
  }

  const { enableManualThreadEscalation, enableManualMessageEscalation } = (
    propertiesData.coreNode as Forum
  ).caseEscalationProperties;

  const canEscalateMessage =
    isForumMessage &&
    salesforceIntegrationEnabled &&
    !isEscalatedMessage &&
    enableManualThreadEscalation &&
    (canEscalateForumMessage(nodePoliciesData.coreNode) ||
      (authUser.id === message.author.id && canEscalateOwnForumMessage(nodePoliciesData.coreNode)));

  const showDropdownItem =
    message.depth === 0 ? canEscalateMessage : canEscalateMessage && enableManualMessageEscalation;

  /**
   * Renders escalate message modal
   */
  const renderEscalateModal = (): React.ReactElement => {
    return (
      <EscalateMessageModal
        show={showEscalateCaseModal}
        onHide={() => setShowEscalateCaseModal(false)}
        message={message}
      />
    );
  };

  return (
    <>
      {showDropdownItem && (
        <>
          <Dropdown.Item
            onClick={(): void => setShowEscalateCaseModal(true)}
            data-testid="EscalateOption"
          >
            {formatMessage('title')}
          </Dropdown.Item>
          {showEscalateCaseModal && renderEscalateModal()}
        </>
      )}
    </>
  );
};

export default MessageActionEscalateToSalesforce;
