import { useApolloClient } from '@apollo/client';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import { FormCheckInputType } from '@aurora/shared-client/components/form/enums';
import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import { fixMutationCallbackError } from '@aurora/shared-client/helpers/apollo/ApolloHelper';
import FormBuilder from '@aurora/shared-client/helpers/form/FormBuilder/FormBuilder';
import { canSendNotificationForMessageAction } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { Form } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext, useId } from 'react';
import { Modal, useClassNameMapper } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../../helpers/boards/ConversationStyleBehaviorHelper';
import { removeCachedReplyFromMessage } from '../../../../helpers/messages/MessageHelper/MessageHelper';
import placeholderDeleteMessageMutation from '../../../../helpers/placeholders/placeholderDeleteMessageMutation';
import type {
  DeleteMessageMutation,
  DeleteMessageMutationVariables,
  MessageActionMenuFragment,
  MessageBasicFieldsFragment,
  MessageBoardFragment,
  MessageParentFragment,
  MessageRepliesCountFragment
} from '../../../../types/graphql-types';
import useTranslation from '../../../useTranslation';
import messageRepliesQuery from '../../MessageReplies.query.graphql';
import formSchema from '../DeleteActionModal/DeleteActionModal.form.json';
import deletedMessageAncestorsQuery from '../DeletedMessageAncestors.query.graphql';
import deleteMessageMutation from '../DeleteMessage.mutation.graphql';
import type { BoardPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import localStyles from './DeleteActionModal.module.pcss';

const log = getLog(module);

/**'
 * The form data
 */
interface FormData {
  /**
   * Notify author field
   */
  notifyAuthor: boolean;
  /**
   * Notify participants field
   */
  notifyParticipants: boolean;
  /**
   * Reason for deletion field
   */
  reason: string;
}

interface Props {
  /**
   * Whether the modal is currently being displayed
   */
  show: boolean;
  /**
   * Callback function when the modal is hidden
   *
   * @callback
   */
  onHide: () => void;
  /**
   * The message action menu fragment
   */
  message: MessageActionMenuFragment;
}

/**
 * Modal that allows user to perform delete action
 *
 * @author Be.Abhijith
 */
const DeleteActionModal: React.FC<React.PropsWithChildren<Props>> = ({
  show,
  onHide,
  message
}: Props) => {
  const uid = useId();
  const i18n = useTranslation(EndUserComponent.DELETE_ACTION_MODAL);
  const { formatMessage, loading: textLoading } = i18n;
  const cx = useClassNameMapper(localStyles);
  const [, setMessageDeletingState] = useGlobalState(GlobalStateType.MESSAGE_DELETING_STATE);
  const { router } = useEndUserRoutes();
  const tenant = useContext(TenantContext);
  const [deleteMessage] = useMutationWithTracing<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >(module, deleteMessageMutation);
  const [frameEnd] = useFrameEnd();
  const client = useApolloClient();
  const { addToast } = useToasts();

  const { data: nodePoliciesData, loading: nodePoliciesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanSendNotificationForMessageAction: true
    },
    !message,
    false
  );

  if (textLoading || nodePoliciesLoading) {
    return null;
  }

  const canViewNotificationFieldSet = canSendNotificationForMessageAction(
    nodePoliciesData?.coreNode
  );

  /**
   * Function to render the error
   * @param errorId - the error id
   * @returns void
   */
  function renderError(errorId: string): void {
    const toastProps: ToastProps = {
      alertVariant: ToastAlertVariant.DANGER,
      toastVariant: ToastVariant.BANNER,
      title: formatMessage('deleteActionErrorTitle'),
      message: formatMessage('deleteActionErrorMessage'),
      autohide: true,
      id: `delete-message-${errorId}-error`
    };
    addToast(toastProps);
  }

  /**
   * onSubmit function for the form
   * @param formData - the form data
   * @param action - the action taken on the form
   * @returns Promise<void>
   */
  async function onSubmit(formData: FormData, action: string): Promise<void> {
    if (action === 'cancel') {
      onHide();
      return;
    }
    const { parent } = message as MessageParentFragment;
    const parentFrameId = getParentFrameId();
    const { notifyAuthor, notifyParticipants, reason } = formData;
    const { data, errors } = await fixMutationCallbackError(deleteMessage)({
      variables: {
        id: message.id,
        includeReplies: true,
        notifyAuthor,
        notifyParticipants,
        reason
      },
      update: (proxy, { errors: updateErrors }): void => {
        if (!(updateErrors?.length > 0) && parent) {
          setMessageDeletingState({ messageId: message.id, parentId: parent.id });
          onHide();
          removeCachedReplyFromMessage(
            tenant,
            proxy,
            parent as unknown as MessageBasicFieldsFragment &
              MessageRepliesCountFragment &
              MessageBoardFragment,
            message
          );
        }
      },
      refetchQueries: [
        {
          query: messageRepliesQuery,
          variables: {
            id: parent?.id
          }
        },
        {
          query: deletedMessageAncestorsQuery,
          variables: {
            id: parent?.id
          }
        }
      ],
      optimisticResponse: placeholderDeleteMessageMutation(),
      context: { parentFrameId },
      onCompleted: (): void => {
        setMessageDeletingState(null);
        frameEnd({
          context: { parentFrameId }
        });
      }
    });

    if (errors?.length > 0) {
      log.error(`Unable to delete message: ${errors}`);
      onHide();
      renderError(message.id);
    } else if (data && message.depth === 0) {
      // Replies get deleted in-place
      client.cache.evict({ id: client.cache.identify(message) });
      client.cache.gc();
      onHide();
      const { board } = message;
      const { boardRoute } = ConversationStyleBehaviorHelper.getInstance(board);
      await router.pushRoute<BoardPagesAndParams>(boardRoute, {
        categoryId: board.parent.displayId,
        boardId: board.displayId
      });
    }
  }

  const formSpec = new FormBuilder<FormData>('DeleteActionForm', i18n, {
    schema: formSchema as Form,
    cx
  })
    .addFieldGroupProperties(
      'deleteProperties',
      { className: cx('lia-g-mb-20') },
      {
        legend: {
          label: formatMessage('deleteProperties'),
          className: cx('lia-g-mb-20')
        }
      }
    )
    .addCheckField({
      name: 'notifyAuthor',
      inputType: FormCheckInputType.CHECKBOX,
      label: formatMessage('DeleteActionForm.notifyAuthor.label'),
      defaultValue: false,
      className: cx('lia-g-mb-10'),
      isVisible: {
        watchFields: null,
        callback: () => canViewNotificationFieldSet
      }
    })
    .addCheckField({
      name: 'notifyParticipants',
      inputType: FormCheckInputType.CHECKBOX,
      label: formatMessage('DeleteActionForm.notifyParticipants.label'),
      defaultValue: false,
      formGroupSpec: {
        showInfo: true
      },
      isVisible: {
        watchFields: null,
        callback: () => canViewNotificationFieldSet
      }
    })
    .addTextAreaField({
      name: 'reason',
      defaultValue: '',
      autoResize: false,
      rows: 3,
      isVisible: {
        watchFields: ['notifyAuthor', 'notifyParticipants'],
        callback: formData => {
          return (
            (formData.notifyAuthor || formData.notifyParticipants) && canViewNotificationFieldSet
          );
        }
      }
    })
    .addSubmitAction({
      variant: ButtonVariant.DANGER,
      buttonType: LoadingButtonVariant.LOADING_BUTTON,
      size: 'lg'
    })
    .addCancelAction()
    .build();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      centered
      aria-labelledby={uid}
      data-testid="DeleteActionModal"
      backdrop="static"
    >
      <Modal.Header className={cx('lia-header')}>
        <Modal.Title id={uid} className={cx('lia-title')}>
          {formatMessage('modalTitle')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={cx('lia-body')}>{formatMessage('modalText')}</p>
        <InputEditForm<FormData> formSpec={formSpec} onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default DeleteActionModal;
