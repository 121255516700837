import type { DeleteMessageMutation } from '../../types/graphql-types';

export default function placeholderDeleteMessageMutation(): DeleteMessageMutation {
  return {
    __typename: 'Mutation',
    deleteMessage: {
      result: false
    }
  };
}
