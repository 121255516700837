import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { canManageAbuseContent } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import {
  ModerationAction,
  ModerationStatus
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type {
  ModerateMessageMutation,
  ModerateMessageMutationVariables,
  ModerationDataQuery,
  ModerationDataQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import moderationDataQuery from '../MessageActionMarkAsSpam/MessageModerationData.query.graphql';
import type { MessageActionType } from '../types';
import markMessageAsNotAbuseMutation from './ModerateMessage.mutation.graphql';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

const log = getLog(module);

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
}

/**
 * The action to approve a message that has been rejected as abuse
 * @constructor
 *
 * @author Manasvini Arul
 */
const MessageActionMarkAsNotAbuse: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_MARK_AS_NOT_ABUSE
  );
  const { isAnonymous } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const uidSeed = useUIDSeed();
  const { addToast } = useToasts();
  const client = useApolloClient();

  const [markMessageAsNotAbuse] = useMutationWithTracing<
    ModerateMessageMutation,
    ModerateMessageMutationVariables
  >(module, markMessageAsNotAbuseMutation);

  const [frameEnd] = useFrameEnd();

  const { data: policiesData, loading: policiesLoading } = useNodePolicies(
    module,
    {
      useCanManageAbuseContent: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  const { data: moderationQueryData, loading: queryLoading } = useQueryWithTracing<
    ModerationDataQuery,
    ModerationDataQueryVariables
  >(module, moderationDataQuery, {
    variables: {
      id: message.id
    },
    skip: IdConverter.isOptimistic(tenant, message?.id)
  });

  const canModerateAbuseMessage = canManageAbuseContent(policiesData?.coreNode);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const { status, rejectReason } = moderationQueryData?.message?.moderationData || {};

  const isRejectedAsAbuse = status === ModerationStatus.Rejected && rejectReason === 'ABUSE';

  if (
    isAnonymous ||
    textLoading ||
    policiesLoading ||
    queryLoading ||
    !canModerateAbuseMessage ||
    !isRejectedAsAbuse
  ) {
    return null;
  }

  /**
   * Renders failure toast messages
   * @param alertVariant The variant for the alert message
   * @param title Title to be displayed
   * @param body Toast body
   * @param autohide whether the toast should be hidden automatically after certain time interval
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    title: string,
    body: string,
    autohide = true
  ): void {
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id: uidSeed(title),
      toastVariant,
      alertVariant,
      title,
      autohide,
      message: body,
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Sets timeout before closing the subjectModal.
   */
  function setTimeOutAndCloseSubjectModal(): void {
    setTimeout(() => onHideSubjectModal(false), 500);
    onCloseSubjectModal();
  }

  /**
   * Action on hide or cancel button click in confirmation dialog
   */
  function onHideOrCancel(): void {
    setShowCancelConfirm(false);
    if (isModalAction) {
      setTimeOutAndCloseSubjectModal();
    }
  }

  /**
   * Action handler for marking a message as not abuse
   */
  async function handleAction(): Promise<void> {
    const mutationOptions: ModerateMessageMutationVariables = {
      messageId: message.id,
      action: ModerationAction.Approve
    };
    const { errors } = await markMessageAsNotAbuse({
      variables: mutationOptions,
      refetchQueries: [
        {
          query: moderationDataQuery,
          variables: {
            id: message.id
          }
        }
      ],
      onCompleted: (): void => {
        const parentFrameId = getParentFrameId();
        frameEnd({
          context: { parentFrameId }
        });
      }
    });
    client.cache.evict({ id: client.cache.identify(message), broadcast: false });
    client.cache.gc();

    if (errors?.length > 0) {
      log.error('Unable to approve message as not abuse', errors);
      renderToast(
        ToastAlertVariant.DANGER,
        formatMessage('unexpected.error.title'),
        formatMessage('unexpected.error.message')
      );
    } else if (isModalAction) {
      setTimeOutAndCloseSubjectModal();
    }
    setShowCancelConfirm(false);
    return;
  }

  function renderConfirmationDialog(): React.ReactElement {
    return (
      showCancelConfirm && (
        <ConfirmationDialog
          show={showCancelConfirm}
          onHide={onHideOrCancel}
          onSubmit={handleAction}
          onCancel={onHideOrCancel}
          titleText={formatMessage('modalTitle')}
          bodyText={formatMessage('modalText')}
          submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
          submitButtonText={formatMessage('modalSubmit')}
        />
      )
    );
  }

  function renderActionButtonOrDropdownItem() {
    const formattedActionText = formatMessage('title');
    return isModalAction ? (
      <Button
        size="lg"
        variant={ButtonVariant.PRIMARY}
        onClick={() => {
          onHideSubjectModal(true);
          setShowCancelConfirm(true);
        }}
      >
        {formattedActionText}
      </Button>
    ) : (
      <Dropdown.Item
        onClick={() => setShowCancelConfirm(true)}
        data-testid="MessageActionMarkAsNotAbuse"
      >
        {formattedActionText}
      </Dropdown.Item>
    );
  }

  return (
    <>
      {renderActionButtonOrDropdownItem()}
      {renderConfirmationDialog()}
    </>
  );
};

export default MessageActionMarkAsNotAbuse;
