import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { ManageUsersPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import type {
  BanUserPolicyQuery,
  BanUserPolicyQueryVariables,
  UserStatusQuery,
  UserStatusQueryVariables
} from '../../../types/graphql-types';
import banUserPolicyQuery from '../../bans/BanUserPolicy.query.graphql';
import useStatusQuery from '../../bans/UserStatus.query.graphql';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';

/**
 * MessageActionEditBanMember Component.
 * Renders Edit ban button.
 *
 * @author Arjun Krishna
 */
const MessageActionEditBanMember: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_EDIT_BAN_MEMBER
  );
  const tenant = useContext(TenantContext);
  const { Link } = useEndUserRoutes();

  const { data: banUserData, loading: banUserLoading } = useQueryWithTracing<
    UserStatusQuery,
    UserStatusQueryVariables
  >(module, useStatusQuery, {
    variables: {
      id: message?.author?.id
    }
  });

  const { data: banPoliciesData, loading: banPoliciesLoading } = useQueryWithTracing<
    BanUserPolicyQuery,
    BanUserPolicyQueryVariables
  >(module, banUserPolicyQuery);

  const { authUser } = useContext(AppContext);
  const anonymousUserId = -1;
  const { isAnonymous } = useRegistrationStatus();

  if (
    isAnonymous ||
    textLoading ||
    banUserLoading ||
    banPoliciesLoading ||
    message?.author.uid === anonymousUserId ||
    message?.author?.uid === authUser?.uid ||
    !banUserData?.user?.banned ||
    banUserData?.user?.deleted ||
    !checkPolicy(banPoliciesData?.self.userPolicies.canBanUsers)
  ) {
    return null;
  }

  return (
    <Link<ManageUsersPageAndParams>
      route={EndUserPages.ManageUsersPage}
      query={{
        [EndUserQueryParams.BANNED_USER]: IdConverter.decodeIdAsParts(tenant, message?.author.id)
          .id,
        [EndUserQueryParams.FILTER_OPEN]: 'true'
      }}
      passHref
      legacyBehavior={true}
    >
      <Dropdown.Item data-testid="MessageActionEditBanMember">
        {formatMessage('editBan')}
      </Dropdown.Item>
    </Link>
  );
};

export default MessageActionEditBanMember;
