import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';

const MessageActionMoveModal = dynamic(
  () => import('./MessageActionMoveModal/ShowMessageMoveActionModal')
);

/**
 * Message action to move a topic
 * @param message
 * @constructor
 *
 * @author Manasvini Arul
 */
const MessageActionMove: React.FC<React.PropsWithChildren<MessageActionType>> = ({ message }) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_MOVE
  );
  const [showMoveTopicModal, setShowMoveTopicModal] = useState<boolean>(false);

  const { isAnonymous } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanMoveMessage: true
    },
    isAnonymous || IdConverter.isOptimistic(tenant, message?.id)
  );
  if (isAnonymous || textLoading || policiesLoading) {
    return null;
  }
  const canMove = checkPolicy(policiesData?.message.messagePolicies?.canMoveMessage);

  const renderMoveTopicModal = (): React.ReactElement => {
    return (
      <MessageActionMoveModal
        show={showMoveTopicModal}
        onHide={() => setShowMoveTopicModal(false)}
        message={message}
      />
    );
  };

  return (
    <>
      {canMove && (
        <Dropdown.Item onClick={(): void => setShowMoveTopicModal(true)}>
          {formatMessage('title')}
        </Dropdown.Item>
      )}
      {showMoveTopicModal && renderMoveTopicModal()}
    </>
  );
};

export default MessageActionMove;
