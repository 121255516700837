import type { MutationFunctionOptions } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import type { HideSpamMutation, HideSpamMutationVariables } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import moderationDataQuery from '../MessageActionMarkAsSpam/MessageModerationData.query.graphql';
import spamMessagesQuery from '../MessageListForContentManagement/SpamMessages.query.graphql';
import type { MessageActionType } from '../types';
import hideSpamMutation from './HideSpam.mutation.graphql';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
}

/**
 * Drop-down item which renders hide spam action button. On click of this button, a potential spam message from the
 * content management dashboard list is  hidden from the Spam Management View.
 *
 * @param message MessageActionMenuFragment of the Message to be hidden
 * @param isModalAction
 * @param onHideSubjectModal
 * @param onCloseSubjectModal
 *
 * @author Prashanth T R
 */
const MessageActionHideSpam: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isModalAction = false,
  onHideSubjectModal,
  onCloseSubjectModal
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_HIDE_SPAM
  );
  const { isAnonymous } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { addToast } = useToasts();
  const client = useApolloClient();
  const hasBeenFocussed = useRef<HTMLButtonElement>();

  useEffect(() => {
    hasBeenFocussed?.current?.focus();
  });

  const [hideSpam] = useMutationWithTracing<HideSpamMutation, HideSpamMutationVariables>(
    module,
    hideSpamMutation
  );

  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanModerateMessage: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  const canModerateMessage =
    policiesData?.message?.messagePolicies?.canModerateSpamMessage.failureReason === null;

  /**
   * Renders an error banner on failure of hideSpamMutation call displaying the error response.
   */
  const renderErrorFeedback = useCallback(
    (error): void => {
      const toastProps: ToastProps = {
        toastVariant: ToastVariant.BANNER,
        alertVariant: ToastAlertVariant.DANGER,
        title: error.name,
        message: error.message,
        autohide: false,
        id: `Hide-Spam-Error-${error.name}`
      };
      addToast(toastProps);
    },
    [addToast]
  );

  /**
   * Renders a success alert on action confirmation.
   */
  const renderSuccessFeedback = useCallback((): void => {
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.FLYOUT,
      alertVariant: ToastAlertVariant.SUCCESS,
      title: formatMessage('successTitle'),
      message: formatMessage('successMessage'),
      autohide: true,
      delay: 4000,
      id: 'Hide-Spam-Success'
    };
    addToast(toastProps);
  }, [addToast, formatMessage]);

  if (isAnonymous || textLoading || policiesLoading) {
    return null;
  }

  /**
   * Handler to perform hideSpamMutation action.
   */
  async function handleAction(): Promise<void> {
    let rollback;
    const mutationPayload: HideSpamMutationVariables = {
      messageId: message.id
    };

    const mutationOptions: MutationFunctionOptions<HideSpamMutation, HideSpamMutationVariables> = {
      variables: mutationPayload,
      refetchQueries: [
        {
          query: moderationDataQuery,
          variables: {
            id: message.id
          }
        },
        {
          query: spamMessagesQuery
        }
      ]
    };

    const { errors } = await hideSpam(mutationOptions);
    client.cache.evict({ id: client.cache.identify(message), broadcast: false });
    client.cache.gc();

    if (errors?.length > 0) {
      if (rollback) {
        rollback();
      }
      errors.forEach(error => renderErrorFeedback(error));
    } else {
      if (isModalAction) {
        setTimeout(() => onHideSubjectModal(false), 500);
        onCloseSubjectModal();
      }
      renderSuccessFeedback();
    }
    setShowConfirmModal(false);
    return;
  }

  function renderHideSpamActionButton() {
    return isModalAction ? (
      <Button
        variant={ButtonVariant.PRIMARY}
        size="lg"
        onClick={() => {
          onHideSubjectModal(true);
          setShowConfirmModal(true);
        }}
        ref={hasBeenFocussed}
      >
        {formatMessage('hideSpam')}
      </Button>
    ) : (
      canModerateMessage && (
        <Dropdown.Item
          onClick={() => setShowConfirmModal(true)}
          data-testid="MessageActionHideSpam"
        >
          {formatMessage('hideSpam')}
        </Dropdown.Item>
      )
    );
  }

  function renderConfirmationDialog() {
    return (
      showConfirmModal && (
        <ConfirmationDialog
          show={showConfirmModal}
          onHide={(): void => {
            setShowConfirmModal(false);
            if (isModalAction) {
              setTimeout(() => onHideSubjectModal(false), 500);
              onCloseSubjectModal();
            }
          }}
          onSubmit={handleAction}
          onCancel={(): void => {
            setShowConfirmModal(false);
            if (isModalAction) {
              setTimeout(() => onHideSubjectModal(false), 500);
              onCloseSubjectModal();
            }
          }}
          titleText={formatMessage('hideSpam')}
          bodyText={formatMessage('confirmationModalBody')}
          submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
          submitButtonText={formatMessage('confirmationModalSubmit')}
        />
      )
    );
  }

  return (
    <>
      {renderHideSpamActionButton()}
      {renderConfirmationDialog()}
    </>
  );
};

export default MessageActionHideSpam;
