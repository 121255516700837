import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import { isDraft } from '../../contentWorkflow/ContentWorkflowActionHelper';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';
import { useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import DeleteActionModal from './DeleteActionModal/DeleteActionModal';
import localStyles from './MessageActionDelete.module.pcss';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';

/**
 * Action to delete a message
 *
 * @constructor
 *
 * @author Patricio Poratto, Adam Ayres
 */
const MessageActionDelete: React.FC<React.PropsWithChildren<MessageActionType>> = ({ message }) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_DELETE
  );
  const { confirmEmailStatus } = useRegistrationStatus();
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const isPreview = useIsPreviewMode();

  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(module, {
    id: message.id,
    useCanDelete: true
  });

  if (textLoading || policiesLoading) {
    return null;
  }

  function handleOnDeleteClick(): void {
    if (!confirmEmailStatus) {
      addUpdateEmailToast();
    } else {
      setShowCancelConfirm(true);
    }
  }

  function renderDeleteAction(): React.ReactElement {
    return checkPolicy(policiesData?.message?.messagePolicies?.canDelete) &&
      !(isDraft(message) && isPreview) ? (
      <Dropdown.Item
        className={cx('lia-action-delete')}
        onClick={(): void => handleOnDeleteClick()}
        data-testid="MessageActionDelete"
      >
        {formatMessage('title')}
      </Dropdown.Item>
    ) : null;
  }

  function renderConfirmationDialog(): React.ReactElement {
    return (
      showCancelConfirm && (
        <DeleteActionModal
          show={showCancelConfirm}
          onHide={(): void => setShowCancelConfirm(false)}
          message={message}
        />
      )
    );
  }

  return (
    <>
      {renderDeleteAction()}
      {renderConfirmationDialog()}
    </>
  );
};

export default MessageActionDelete;
