import type AsyncSearchableSelectFieldEntry from '@aurora/shared-client/components/form/AsyncSearchableSelectField/AsyncSearchableSelectFieldEntry';
import type { User } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import type { MessageActionMenuFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';

const PrivateConversationCreationModal = dynamic(
  () => import('../../inbox/PrivateConversationCreationModal/PrivateConversationCreationModal'),
  {
    ssr: false
  }
);

/**
 * The action to send a private message to the author.
 * @constructor
 *
 * @author Arjun Krishna
 */

interface Props {
  /**
   * The message in action.
   */
  message: MessageActionMenuFragment;
}

const MessageActionSendMessage: React.FC<React.PropsWithChildren<Props>> = ({ message }) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_SEND_MESSAGE
  );
  const [showModal, setShowModal] = useState(false);

  if (textLoading) {
    return null;
  }

  const { author, subject } = message;
  const users: AsyncSearchableSelectFieldEntry<User>[] = [];
  users.push({
    label: author?.login,
    value: author?.login,
    entity: author as User
  });

  return (
    <>
      <Dropdown.Item onClick={() => setShowModal(true)}>{formatMessage('title')}</Dropdown.Item>
      {showModal && (
        <PrivateConversationCreationModal
          show={showModal}
          onHide={() => setShowModal(false)}
          isSendBroadcast={false}
          recipientUsers={users}
          defaultSubjectField={formatMessage('messageSubject', { subject: subject })}
        />
      )}
    </>
  );
};
export default MessageActionSendMessage;
