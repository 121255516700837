import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import { hasErrors } from '@aurora/shared-client/helpers/apollo/ApolloHelper';
import { AbuseModerationMessageAction } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type {
  ModerateAbuseMessageMutation,
  ModerateAbuseMessageMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import ignoreAbuseMutation from '../ModerateAbuseMessage.mutation.graphql';
import type { MessageActionType } from '../types';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
}

/**
 * Drop-down item which renders ignore action button. On click of this button,
 * an abuse message is removed from the from the Abuse Management View.
 *
 * @author Amit Agrawal
 */
const MessageActionIgnoreAbuse: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_IGNORE_ABUSE
  );
  const client = useApolloClient();
  const { addToast } = useToasts();
  const uidSeed = useUIDSeed();
  const [showModal, setShowModal] = useState<boolean>(false);

  const [ignoreAbuse] = useMutationWithTracing<
    ModerateAbuseMessageMutation,
    ModerateAbuseMessageMutationVariables
  >(module, ignoreAbuseMutation);

  const [frameEnd] = useFrameEnd();

  if (textLoading) {
    return null;
  }

  /**
   * Renders toast banner or flyout
   *
   * @param alertVariant toast alert variant.
   * @param key
   * @param titleKey toast title.
   * @param messageKey toast message.
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    titleKey: string,
    messageKey: string
  ): void {
    const id = uidSeed(`ignore-abuse-${titleKey}`);
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id,
      toastVariant,
      alertVariant,
      title: formatMessage(`ignoreAbuse.${titleKey}`),
      autohide: true,
      message: formatMessage(`ignoreAbuse.${messageKey}`),
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Action handler for ignore abuse action
   */
  async function handleIgnoreAbuseAction(): Promise<void> {
    const mutationPayload: ModerateAbuseMessageMutationVariables = {
      entityId: message.id,
      action: AbuseModerationMessageAction.Ignore
    };

    const {
      data: {
        moderateAbuseMessage: { errors: knownErrors }
      },
      errors
    } = await ignoreAbuse({
      variables: mutationPayload,
      onCompleted: (d): void => {
        if (!hasErrors(d.moderateAbuseMessage)) {
          const parentFrameId = getParentFrameId();
          frameEnd({
            context: { parentFrameId }
          });
        }
      }
    });

    if (errors?.length > 0 || (knownErrors && knownErrors.length > 0)) {
      if (knownErrors[0].__typename === 'PermissionDeniedError') {
        renderToast(
          ToastAlertVariant.DANGER,
          'permissionDenied.errorTitle',
          'permissionDenied.errorMessage'
        );
      } else if (knownErrors[0].__typename === 'EntityAlreadyIgnoredAsNotAbuseError') {
        renderToast(
          ToastAlertVariant.DANGER,
          'alreadyModerated.errorTitle',
          'alreadyModerated.errorMessage'
        );
      } else {
        renderToast(ToastAlertVariant.DANGER, 'errorTitle', 'errorMessage');
      }
      return;
    }
    if (isModalAction) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    client.cache.evict({ id: client.cache.identify(message) });
    client.cache.gc();
    renderToast(ToastAlertVariant.SUCCESS, 'successTitle', 'successMessage');
    setShowModal(false);
  }

  /**
   * Handles hide or cancel dialog action
   */
  function onHideOrCancelDialog() {
    if (isModalAction) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    setShowModal(false);
  }

  /**
   * Renders the confirmation dialog for ignore abuse
   */
  function renderIgnoreAbuseDialog(): React.ReactElement {
    return (
      <ConfirmationDialog
        show={showModal}
        onHide={() => onHideOrCancelDialog()}
        onSubmit={() => handleIgnoreAbuseAction()}
        onCancel={() => onHideOrCancelDialog()}
        titleText={formatMessage('modal.title')}
        bodyText={formatMessage('modal.body')}
        submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
        submitButtonText={formatMessage('modal.submit')}
      />
    );
  }

  return (
    <>
      {isModalAction ? (
        <Button
          data-testid="MessageActionIgnoreAbuseButton"
          variant={ButtonVariant.LIGHT}
          size="lg"
          onClick={() => {
            onHideSubjectModal(true);
            setShowModal(true);
          }}
        >
          {formatMessage('menuItem.ignoreAbuse')}
        </Button>
      ) : (
        <Dropdown.Item onClick={() => setShowModal(true)} data-testid="MessageActionIgnoreAbuse">
          {formatMessage('menuItem.ignoreAbuse')}
        </Dropdown.Item>
      )}
      {showModal && renderIgnoreAbuseDialog()}
    </>
  );
};

export default MessageActionIgnoreAbuse;
