import React from 'react';
import type { MessageActionType } from '../types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import CopyMessageLink from '../CopyMessageLink/CopyMessageLink';

/**
 * Action to copy a message's link.
 *
 * @author Vaibhav Chawla
 *
 * @constructor
 */
const MessageActionCopyLink: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  return (
    <CopyMessageLink message={message} componentId={EndUserComponent.MESSAGE_ACTION_COPY_LINK} />
  );
};

export default MessageActionCopyLink;
