import type { ApolloCache } from '@apollo/client';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { hasErrors } from '@aurora/shared-client/helpers/apollo/ApolloHelper';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type {
  MessageActionMenuFragment,
  TurnOffCommentsMutation,
  TurnOffCommentsMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';
import turnOffCommentsMutation from './TurnOffComments.mutation.graphql';

export enum TurnOffCommentsActions {
  TURN_OFF = 'turnOffComments',
  TURN_ON = 'turnOnComments'
}

/**
 * Action to turn off comments/replies
 * @param message
 * @constructor
 *
 * @author Shraddha Padmanabhan
 */
const MessageActionTurnOffComments: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_TURN_OFF_COMMENTS
  );
  const { addToast } = useToasts();
  const uidSeed = useUIDSeed();
  const tenant = useContext(TenantContext);
  const [turnOffComments] = useMutationWithTracing<
    TurnOffCommentsMutation,
    TurnOffCommentsMutationVariables
  >(module, turnOffCommentsMutation);
  const [frameEnd] = useFrameEnd();
  const { isAnonymous } = useRegistrationStatus();
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanMarkMessageReadOnly: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  if (isAnonymous || textLoading || policiesLoading) {
    return null;
  }

  /**
   * Renders toast banner or flyout
   *
   * @param alertVariant toast alert variant.
   * @param key
   * @param titleKey toast title.
   * @param messageKey toast message.
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    key: string,
    titleKey: string,
    messageKey: string
  ): void {
    const id = uidSeed(`turn-off-comments-${titleKey}`);
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id,
      toastVariant,
      alertVariant,
      title: formatMessage(`${key}.${titleKey}.${message.board.conversationStyle}`),
      autohide: true,
      message: formatMessage(`${key}.${messageKey}.${message.board.conversationStyle}`),
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Updates cache
   * @param cache
   * @param isReadOnly
   * @param messageInformation
   */
  function updateCache(
    cache: ApolloCache<{}>,
    isReadOnly: Boolean,
    messageInformation: MessageActionMenuFragment
  ): () => void {
    const id = cache.identify(message);
    const connection = messageInformation.readOnly;
    async function doUpdate(contextMessage): Promise<void> {
      cache.modify({
        id,
        fields: {
          readOnly() {
            return contextMessage?.readOnly;
          }
        }
      });
    }

    const data = {
      id,
      readOnly: isReadOnly
    };
    doUpdate(data);
    // return a rollback function
    return (): void => {
      doUpdate(connection);
    };
  }

  /**
   * Action handler for turning off/on replies
   */
  async function handleAction(): Promise<void> {
    let rollback;
    const key = message.readOnly ? TurnOffCommentsActions.TURN_ON : TurnOffCommentsActions.TURN_OFF;
    const mutationPayload: TurnOffCommentsMutationVariables = {
      id: message.id,
      isReadOnly: !message.readOnly
    };
    const parentFrameId = getParentFrameId();
    const { data: response, errors } = await turnOffComments({
      variables: mutationPayload,
      update: (cache, { errors: updateErrors }): void => {
        if (!updateErrors?.length) {
          rollback = updateCache(cache, !message.readOnly, message);
        }
      },
      onCompleted: (d): void => {
        if (!hasErrors(d.setMessageReadOnly)) {
          frameEnd({
            context: { parentFrameId }
          });
        }
      },
      context: { parentFrameId }
    });
    if (errors?.length > 0 || hasErrors(response?.setMessageReadOnly)) {
      if (rollback) {
        rollback();
      }
      renderToast(ToastAlertVariant.DANGER, key, 'failureTitle', 'failureMessage');
      return;
    }
    renderToast(ToastAlertVariant.INFO, key, 'successTitle', 'successMessage');
  }

  const canTurnOffComments = checkPolicy(
    policiesData?.message.messagePolicies?.canMarkMessageReadOnly
  );
  return (
    canTurnOffComments && (
      <Dropdown.Item onClick={() => handleAction()} data-testid="MessageActionTurnOffComments">
        {message.readOnly
          ? formatMessage(
              `menuItem.${TurnOffCommentsActions.TURN_ON}.${message.board.conversationStyle}`
            )
          : formatMessage(
              `menuItem.${TurnOffCommentsActions.TURN_OFF}.${message.board.conversationStyle}`
            )}
      </Dropdown.Item>
    )
  );
};

export default MessageActionTurnOffComments;
