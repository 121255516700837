import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { MessageViewToggleAction } from '../../../types/enums';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';

interface Props extends MessageActionType {
  /**
   * Callback triggered to toggle the accordion.
   */
  toggleAccordion?: () => void;
  /**
   * Whether to use Collapse or Expand in the Action Menu Item.
   */
  currentState: string;
}

/**
 * Action to expand or collapse a message and it's replies.
 *
 * @constructor
 *
 * @author Medha Smriti
 */
const MessageActionToggleView: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  toggleAccordion,
  currentState = null
}) => {
  const { formatMessage } = useTranslation(EndUserComponent.MESSAGE_ACTION_TOGGLE_VIEW);

  if (message === null || currentState === null) {
    return null;
  }

  return (
    message.repliesCount > 0 && (
      <Dropdown.Item
        onClick={() => {
          toggleAccordion();
        }}
        data-testid="MessageActionToggleView"
      >
        {currentState === MessageViewToggleAction.COLLAPSE
          ? formatMessage('titleCollapse')
          : formatMessage('titleExpand')}
      </Dropdown.Item>
    )
  );
};

export default MessageActionToggleView;
