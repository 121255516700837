import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { IdeaReplyMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';

const MoveTypeModal = dynamic(() => import('./MoveTypeModal/MoveTypeModal'));

/**
 * Message action to move a comment to a post
 * @param message
 * @constructor
 *
 * @author Shraddha Padmanabhan
 */
const MessageActionMoveComment: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_MOVE_COMMENT
  );
  const [showMoveTypeModal, setShowMoveTypeModal] = useState<boolean>(false);
  const { isAnonymous } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanMoveMessage: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );
  if (isAnonymous || textLoading || policiesLoading) {
    return null;
  }
  const canMove = checkPolicy(policiesData?.message?.messagePolicies?.canMoveMessage);

  /**
   * Renders the move comment modal
   */
  function renderMoveTypeModal(): React.ReactElement {
    return (
      <MoveTypeModal
        show={showMoveTypeModal}
        onHide={() => setShowMoveTypeModal(false)}
        message={message}
      />
    );
  }

  const previousStatus = (message as IdeaReplyMessage)?.status?.previousStatus;
  const currentStatus = (message as IdeaReplyMessage)?.status?.currentStatus;
  const isStatusChangeComment =
    message.depth !== 0 &&
    message.board.conversationStyle === ConversationStyle.Idea &&
    (currentStatus !== null || previousStatus !== null);

  return (
    <>
      {canMove && !isStatusChangeComment && (
        <Dropdown.Item
          onClick={(): void => setShowMoveTypeModal(true)}
          data-testid="MoveCommentDropdownItem"
        >
          {formatMessage('title')}
        </Dropdown.Item>
      )}
      {showMoveTypeModal && renderMoveTypeModal()}
    </>
  );
};
export default MessageActionMoveComment;
