import { useApolloClient } from '@apollo/client';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { BoardPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { ContentWorkflowState } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import type {
  DeleteDraftMutation,
  DeleteDraftMutationVariables,
  MessageContentWorkflowFragment
} from '../../../types/graphql-types';
import { isDraft } from '../../contentWorkflow/ContentWorkflowActionHelper';
import useTranslation from '../../useTranslation';
import localStyles from '../MessageActionDelete/MessageActionDelete.module.pcss';
import type { MessageActionType } from '../types';
import { useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import deleteDraftMutation from './DeleteDraftMessage.mutation.graphql';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

/**
 *  Action to delete a draft message
 *
 *  @author Arjun Krishna
 */

const MessageActionDeleteDraft: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage } = useTranslation(EndUserComponent.MESSAGE_ACTION_DELETE_DRAFT);
  const { router } = useEndUserRoutes();
  const tenant = useContext(TenantContext);
  const { isAnonymous } = useRegistrationStatus();
  const client = useApolloClient();

  const [deleteDraftMessage] = useMutationWithTracing<
    DeleteDraftMutation,
    DeleteDraftMutationVariables
  >(module, deleteDraftMutation);

  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanDeleteDraft: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  const isPreview = useIsPreviewMode();
  const { route } = router.getCurrentRouteAndParams();

  if (policiesLoading) {
    return null;
  }

  const canRenderDeleteDraftAction =
    (isDraft(message) && isPreview) || route === EndUserPages.ManageContentPage;

  const isScheduledForPublish =
    (message as MessageContentWorkflowFragment)?.contentWorkflow?.state ===
    ContentWorkflowState.ScheduledForPublish;

  /**
   *  Renders the delete action
   */
  function renderDeleteDraftAction(): React.ReactElement {
    return canRenderDeleteDraftAction &&
      checkPolicy(policiesData?.message?.messagePolicies?.canDeleteDraft) ? (
      <Dropdown.Item
        className={cx('lia-action-delete')}
        onClick={(): void => {
          setShowCancelConfirm(true);
        }}
        data-testid="MessageActionDelete"
      >
        {formatMessage('title')}
      </Dropdown.Item>
    ) : null;
  }

  async function confirmDelete() {
    await deleteDraftMessage({
      variables: { id: message.id }
    });
    setShowCancelConfirm(false);
    client.cache.evict({ id: client.cache.identify(message) });
    client.cache.gc();
    const { board } = message;
    const { boardRoute } = ConversationStyleBehaviorHelper.getInstance(board);

    if (isPreview) {
      if (message?.latestVersion?.major !== '0') {
        await router.removeQueryParam([EndUserQueryParams.PREVIEW_MESSAGE]);
      } else {
        await router.pushRoute<BoardPagesAndParams>(boardRoute, {
          categoryId: board.parent.displayId,
          boardId: board.displayId
        });
      }
    }
  }

  function renderConfirmationDialog(): React.ReactElement {
    return (
      showCancelConfirm && (
        <ConfirmationDialog
          show={showCancelConfirm}
          onHide={(): void => setShowCancelConfirm(false)}
          onSubmit={confirmDelete}
          onCancel={(): void => setShowCancelConfirm(false)}
          titleText={formatMessage('modalTitle')}
          bodyText={
            isScheduledForPublish
              ? formatMessage('deleteDraftConfirmationText')
              : formatMessage('modalText')
          }
          submitButtonVariant={ButtonVariant.DANGER}
          submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
          submitButtonText={formatMessage('modalSubmit')}
        />
      )
    );
  }

  return (
    <>
      {renderDeleteDraftAction()} {renderConfirmationDialog()}
    </>
  );
};
export default MessageActionDeleteDraft;
